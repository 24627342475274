<template>
  <!-- 
    画面: キャンセル規定新規登録
    用途: キャンセル規定を新規登録する
   -->
  <div>
    <!-- #region TODO -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            {{ $t("cancelPolicyCreatePage.pageTitle") }}
          </CCardHeader>

          <CCardBody>
            <span class="caption">
              {{ $t("cancelPolicyCreatePage.caption") }}
            </span>
            <CForm class="submit_form">
              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("cancelPolicyCreatePage.name")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("cancelPolicyCreatePage.name"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("cancelPolicyCreatePage.name"),
                        vMax: $v.edit.name.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("cancelPolicyCreatePage.description")
                  }}</strong></CCol
                >
                <CCol>
                  <CTextarea class="mb-2" v-model="edit.description" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("cancelPolicyEditPage.description"),
                        vMax: $v.edit.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton color="secondary" size="md" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion TODO -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">
        <!-- #region 名称 -->
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("cancelPolicyCreatePage.name") }}</strong></CCol
          >
          <CCol
            ><span> {{ edit.name }} </span></CCol
          >
        </CRow>
        <!-- #endregion 名称 -->

        <!-- #region 説明 -->
        <CRow>
          <CCol class="label"
            ><strong>{{
              $t("cancelPolicyCreatePage.description")
            }}</strong></CCol
          >
          <CCol
            ><span> {{ edit.description }} </span></CCol
          >
        </CRow>
        <!-- #endregion 説明 -->
      </CForm>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton @click="checkModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton color="success" @click="onRegisterClicked()">
          <i class="icon cil-save mr-1"></i> {{ $t("common.register") }}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Check -->

    <!-- #region Success -->
    <SuccessModal :successModal="successModal" @close="successModal = $event" />
    <!-- #endregion Success -->

    <!-- #region Error -->
    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
    <!-- #endregion Error -->
  </div>
</template>

<script>
// import i18n from "../i18n";
import axios from "axios";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "CancelPolicyCreate",

  components: {
    SuccessModal,
    ErrorModal,
  },

  computed: {
    uid() {
      //return this.$route.params.UniqueId;
      return this.$store.state.uniqueId;
    },
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },
  },
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag

      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,

      //#endregion Flag

      //#region Message
      errorMessage: "error",
      //#endregion Message

      //#region edit

      edit: {
        code: "",
        name: "",
        description: "",
      },

      //#endregion edit

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event

    /** 確認ボタン押下 */
    onCheckClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : (this.checkModal = true);
    },

    /** 登録ボタン押下 */
    onRegisterClicked() {
      this.reqPost();
    },

    /** キャンセルボタン押下 */
    onBackClicked() {
      this.$router.push({
        path: `/operator/cancelPolicy/list`,
      });
    },

    //#endregion Event

    //#region Request

    /** プランタイプ登録リクエスト */
    reqPost() {
      const url = `/rest/hotel/${this.uid}/cancelpolicy`;
      const body = this.edit;

      axios
        .post(url, body, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log("resp.data" + JSON.stringify(resp.data));
          this.successModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = err;
          this.errorModal = true;
        });
    },

    //#endregion Request
  },
};
</script>
